$primary-color: hsl(0, 100%, 70%);
$secondary-color: hsl(191, 100%, 40%);
$dark-color: hsl(234, 30%, 18%);
$light-color: hsl(210, 14%, 58%);

.section {
  position: relative;
  padding: 2rem 20%;
  width: 100%;
  // box-sizing: border-box;
}

@media only screen and (max-width: 1285px) {
  .section {
    padding: 2rem 18%;
  }
}

@media only screen and (max-width: 1206px) {
  .section {
    padding: 2rem 15%;
  }
}

@media only screen and (max-width: 1106px) {
  .section {
    padding: 2rem 13%;
  }
}

@media only screen and (max-width: 1045px) {
  .section {
    padding: 2rem 10%;
  }
}

@media only screen and (max-width: 970px) {
  .section {
    padding: 2rem 7%;
  }
}

@media only screen and (max-width: 902px) {
  .devImg_BelowTablet {
    display: block !important;
  }
  .devImg_UptoTablet {
    display: none;
  }
  .headerContainer {
    align-items: center;
  }
  .headerTextImg {
    justify-content: center !important;
    align-items: center !important;

    .headerText {
      text-align: center !important;
    }
  }

  @media only screen and (max-width: 650px) {
    .section {
      padding: 2rem 5%;
    }
    .headerText {
      width: auto !important;

      h4 {
        font-size: 40px !important;
        line-height: 45px !important;
      }
    }
  }
}

.intro_Section {
  // display: flex;
  // justify-content: space-between;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  .headerTextImg {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.headerText {
  width: 500px;
  color: $dark-color;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  h4 {
    font-size: 44px;
    font-weight: 700;
    line-height: 60px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

@mixin devImg {
  position: relative;
  width: 245px;
  height: 245px;
  overflow: hidden;
  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
}
.devImg_BelowTablet {
  display: none;
  @include devImg();
}
.devImg_UptoTablet {
  @include devImg();
}

.downloadBtn {
  margin-top: 1rem !important;
  border-radius: 0 !important;
  background-color: $primary-color !important;
  color: #fff !important;
}

.recentPost_Section {
  background-color: #edf7fa;

  .postsCard {
    padding: 1rem;
    display: grid;
    gap: 0.5rem;
    span:nth-child(1) {
      font-size: 26px;
      font-weight: 700;
      line-height: 33px;
    }
    span:nth-child(2) {
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
    }
    span:nth-child(3) {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.featuredWork_Section {
  .featureWorkCard {
    height: 200px;
    display: flex;
    gap: 1rem;
    img {
      height: inherit;
      border-radius: 6px;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .workHeading {
        font-size: 30px;
        font-weight: 700;
        line-height: 44px;
        color: $dark-color;
      }

      > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        span:nth-child(1) {
          color: #fff;
          background-color: $dark-color;
          font-size: 15px;
          font-weight: 800;
          padding: 1px 9px;
          border-radius: 10000px;
        }
        span:nth-child(2) {
          font-size: 15px;
          font-weight: 400;
          color: $light-color;
        }
      }
    }
  }
}
