@mixin a-tag-animation {
}

.mainContainer {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 4rem;

  .activeLink {
    color: rgb(6, 155, 255) !important;
  }

  div:nth-child(2) {
    display: flex;
    gap: 2rem;
    a {
      color: #000;
      position: relative;
      font-size: 20px;
      font-weight: 500;
      line-height: 29px;
      text-decoration: none;
      overflow: hidden;
      &::before {
        position: absolute;
        content: attr(data-content);
        top: 0;
        left: 0;
        color: red;
        //   text-decoration: underline;
        overflow: hidden;
        transition: width 300ms ease-in-out;
        width: 0;
        white-space: nowrap;
      }
      &:hover::before {
        width: 100%;
      }
      &::after {
        display: inline-block;
        content: "";
        width: 0%;
        height: 2px;
        background-color: red;
        transition: width 300ms ease-in-out;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &:hover::after {
        width: 100%;
      }
    }
  }
}

.children {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 260px);
  box-sizing: border-box;
}

.footer {
  height: 180px;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div:nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
}

@media only screen and (max-width: 910px) {
  .header {
    padding: 1.6rem 2rem;
  }
}

.mobileMenu {
  display: grid;
  justify-items: center;
  background-color: bisque;
  // gap: 1rem;
  a {
    outline: none;
    text-align: center;
    width: 100%;
    overflow: visible;
    backface-visibility: hidden;
    background-color: transparent;
    color: #000;
    position: relative;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    text-decoration: none;
    padding: 10px 5px;
    &:focus {
      background-color: transparent;
    }
  }
}

@media only screen and (max-width: 650px) {
  .header {
    padding: 0.5rem 0rem;
  }
}
