.container {
  margin: 0 !important;
  padding: 2rem 20%;
  width: 100% !important;
}

@media only screen and (max-width: 1285px) {
  .container {
    padding: 2rem 18%;
  }
}

@media only screen and (max-width: 1206px) {
  .container {
    padding: 2rem 15%;
  }
}

@media only screen and (max-width: 1106px) {
  .container {
    padding: 2rem 13%;
  }
}

@media only screen and (max-width: 1045px) {
  .container {
    padding: 2rem 10%;
  }
}

@media only screen and (max-width: 970px) {
  .container {
    padding: 2rem 7%;
  }
}

@media only screen and (max-width: 760px) {
  .container {
    padding: 2rem 5%;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 0rem 0;
  }
}

.heading {
  font-size: 44px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 2rem;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  span:nth-child(1) {
    font-family: Heebo;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0px;
    text-align: left;
  }
  span:nth-child(2) {
    font-family: Heebo;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
  }
  span:nth-child(3) {
    font-family: Heebo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
}
